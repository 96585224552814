import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconCheck(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m6 12l4.243 4.243l8.484-8.486"
			/>
		</Icon>
	);
}
